import React from "react";
import "./Section.css";

export default function Section(props) {
  const orderClassName = props.order === "2" ? "order" : "";

  return (
    <section className="section" id={props.id}>
      <picture className={`img-holder ${orderClassName}`}>
        <source
          className="thumbnail"
          srcset={props.image + ".jp2"}
          type="image/jp2"
        />
        <source
          className="thumbnail"
          srcset={props.image + ".webp"}
          type="image/webp"
        />
        <img className="thumbnail" src={props.image + ".jpeg"} alt={props.id} />
      </picture>
      <div className="section-text">
        <h2>{props.title}</h2>
        <p>{props.content}</p>
      </div>
    </section>
  );
}
