import React, { Component, PureComponent } from "react";
import "./Navigation.css";

class NavItem extends Component {
  render() {
    return (
      <a className="nav-link" href={this.props.href}>
        {this.props.text}
      </a>
    );
  }
}

const NavList = function () {
  return (
    <>
      <NavItem href="#design" text="Design" />

      <NavItem href="#sales" text="Sales" />

      <NavItem href="#installation" text="Installation" />

      <NavItem href="#maintenance" text="Maintenance" />

      <NavItem href="#service_repair" text="Service & Repair" />
    </>
  );
};

const Toggle = function (props) {
  return (
    <div
      className={`nav-toggle ${props.open ? "change" : ""}`}
      onClick={() => props.handleToggle()}
    >
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

class MobileNavigation extends PureComponent {
  render() {
    return (
      <div
        className={`mobile-navigation ${this.props.open ? "open" : "closed"}`}
      >
        <NavList></NavList>
        <ContactDetails></ContactDetails>
      </div>
    );
  }
}

const ContactDetails = () => {
  return (
    <div className="contact-details">
      <span itemprop="telephone">
        <a href="tel:+35314528949">&#x260F; (01) 452 8949</a>
      </span>
      <span itemprop="email">
        <a href="mailto:info@refrigerationsolutions.ie" itemprop="email">
          &#9993; info@refrigerationsolutions.ie
        </a>
      </span>
    </div>
  );
};

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousScrollPosition: window.pageYOffset,
      visible: true,
      open: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { previousScrollPosition } = this.state;

    const currentScrollPosition = window.pageYOffset;
    const visible = previousScrollPosition > currentScrollPosition;

    this.setState({
      previousScrollPosition: currentScrollPosition,
      visible,
      open: false,
    });
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
    console.log(`Is open: ${this.state.open}`);
  };

  render() {
    return (
      <>
        <nav
          className={`navigation 
        ${this.state.visible ? "navigation-pinned" : "navigation-unpinned"}`}
        >
          <Toggle open={this.state.open} handleToggle={this.toggle}></Toggle>
          <div className="navigation-content">
            <div className="navigation-logo">
              <img src="logo.png" alt="logo" />
            </div>
            <div className="navigation-menu">
              <NavList></NavList>
            </div>
            <div className="navigation-contact-details">
              <ContactDetails></ContactDetails>
            </div>
          </div>
        </nav>
        <MobileNavigation open={this.state.open}></MobileNavigation>
      </>
    );
  }
}
