import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div
      itemscope
      itemtype="http://schema.org/LocalBusiness"
      className="footer"
    >
      <span itemprop="telephone">
        <a href="tel:+35314528949">&#x260F; (01) 452 8949</a>
      </span>
      <span itemprop="email">
        <a href="mailto:info@refrigerationsolutions.ie" itemprop="email">
          &#9993; info@refrigerationsolutions.ie
        </a>
      </span>
    </div>
  );
}
