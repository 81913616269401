import React from "react";
import "./Banner.css";

export default function Banner() {
  return (
    <div className="page-header">
      <picture className="banner">
        <source srcset="banner.webp" type="image/webp" />
        <source srcset="banner.jp2" type="image/jp2" />
        <img src="banner.jpeg" alt="banner" />
      </picture>
    </div>
  );
}
