import React from "react";
import "./App.css";
import Navigation from "./components/Navigation.js";
import Banner from "./components/Banner";
import Section from "./components/Section";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div className="wrapper">
      <div className="white-bg">
        <header>
          <Navigation></Navigation>
        </header>
      </div>
      <main>
        <div className="container">
          <Banner></Banner>
        </div>
        <div className="main-content">
          <div className="container">
            <Section
              id="design"
              image="refrigeration_design"
              title="Design"
              content="Refrigeration Solutions can tailor a solution for your requirements. Our design team can complete a site survey and provide you with a competitive and energy efficient solution. Site visits can be arranged if clients wish to inspect previous similar projects."
            ></Section>
            <Section
              id="sales"
              order="2"
              image="refrigeration_sales"
              title="Sales"
              content="We offer Refrigeration and Air Conditioning equipment sales including, supermarkets cases, serve overs, glass door merchandisers, chill and freezer rooms, ice makers, blast chillers, Air-conditioning systems, dehumidifiers, air curtains and ventilation."
            ></Section>
            <Section
              id="installation"
              image="refrigeration_installation"
              title="Installation"
              content="Every installation is project managed, with a site specific method statement prepared following a visit from one of our project managers. A schedule of work is prepared and with the agreement of the client. Installations are completed by our industry trained engineers, commissioned and certified."
            ></Section>
            <Section
              id="maintenance"
              order="2"
              image="refrigeration_service"
              title="Maintenance"
              content="Continuous maintenance is key to an efficient refrigeration system. We offer maintenance contracts to suit our clients and ensure that all equipment gives optimum performance and efficiency by implementing meticulous maintenance checks."
            ></Section>
            <Section
              id="service_repair"
              image="refrigeration_repair_maintenance"
              title="Service & Repair"
              content="We pride ourselves on delivering outstanding customer care. We can offer equipment testing, gas leak detection, temperature mapping, probe and system calibration, monitoring of running costs, filter, drain and condensate pump cleaning, reactive repairs and overall advice at keeping equipment in good condition.
            We run an On-Call System which means that there is a Service Engineer available to all our maintenance customers 24-7"
            ></Section>
          </div>
        </div>
      </main>
      <footer>
        <Footer></Footer>
      </footer>
    </div>
  );
}
